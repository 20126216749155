em{
  &.icon {
    display: inline-block;
    width: 1em;
    height: auto;
    vertical-align: middle;
  }
  &.right-arrow {
    content: url('/dist/images/right-arrow.svg');
  }
  &.right-arrow.green {
    content: url('/dist/images/right-arrow-green.svg');
  }
  &.left-arrow {
    content: url('/dist/images/left-arrow.svg');
  }
  &.tags {
    content: url('/dist/images/tag.svg');
  }
  &.tags-light {
    content: url('/dist/images/tag-light.svg');
  }
  &.zoom {
    content: url('/dist/images/zoom.svg');
  }
  &.zoom-light {
    content: url('/dist/images/zoom-light.svg');
  }
  &.work-together {
    content: url('/dist/images/work-together.svg');
  }
  &.comments {
    content: url('/dist/images/comments.svg');
  }
  &.lg {
    width: 2em;
  }
  &.xl {
    width: 3em;
  }
  &.xxl {
    width: 4em;
  }
}

object.draw {
  &.lg {
    width: 2em;
    height: auto;
  }
  &.xl {
    width: 3em;
    height: auto;
  }
  &.xxl {
    width: 4em;
    height: auto;
  }
}