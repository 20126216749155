// Menu

//#mobileNavIcon {
//  display: inline-block;
//  vertical-align: middle;
//  width: 22px;
//  height: 22px;
//  background: url('/dist/images/menu.svg') no-repeat top left;
//  background-size: contain;
//}

.navbar-default {
  height: 100px;
  background-color: rgba(255,255,255,0.9);
  border-color: #333;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.06);
  transition: transform .5s;
  &.is-hidden {
    transform: translateY(-90%);
  }
}

// Menu
.greedy-nav {
  min-width: 250px;
  background: $primary-color;
  .navbar-brand {
    background: transparent;
    border-bottom: none !important;
    img {
      min-width: 86px;
      width: 86px;
      height: auto;
      transform: rotate(0deg) scale(1);
      transition: all 0.4s;
      &:hover {
        transform: rotate(-5deg) scale(1.2);
      }
    }
  }

  li {
    vertical-align: middle;
    &.active {
      > a {
        color: #fff;
        border-bottom: 3px solid #fff;
      }
    }

    &.parent {
      a {
        border: 1px solid $secondary-color !important;
      }
    }

    &.dropdown {
      a.dropdown-toggle {
        &:after {
          display: block;
          position: absolute;
          top: 3rem;
          right: .2rem;
        }
      }
    }

    a {
      display: block;
      background: transparent;
      text-decoration: none;
      color: #fff;
      border-bottom: 3px solid transparent;
      font-size: 1em;
      font-weight: 700;
      text-transform: lowercase;
      padding: 10px 18px;
      transition: all 0.4s;
      &:hover, &:focus, &:active {
        color: #fff;
        border-bottom: 3px solid #fff;
      }
    }
  }

  .dropdown:hover, .dropdown.open {
    .dropdown-menu {
      margin-top: 0;
      border-radius: 0;
      display: block !important;
      left: inherit;
    }
  }

  .dropdown-menu {
    li {
      display: block !important;
      width: 100%;
      padding: 15px 15px;
      a {
        transition: all 0.2s;
        border-radius: 5px;
        border: 1px solid transparent;
        &:hover, &:active, &:focus {
          background-color: #f6f6f6;
          border: 1px solid #efefef;
        }
      }
    }
  }

  .hidden-links {
    border: 1px solid rgba(0,0,0,.15);
    width: 100%;
    padding: 0;
    background-color: #fff;
    li {
      width: 100%;
      a {
        color: #444;
        padding: 15px 60px;
        text-align: center;
        &:hover, &:active, &:focus {
          color: $primary-color;
        }
        &:after {
          display: inline-block !important;
          position: inherit !important;
          top: 0 !important;
          left: 0 !important;
        }
      }
    }
    .dropdown-menu {
      left: 0;
      li {
        padding: 15px 15px;
      }
    }
  }

  button {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    padding: 0 15px;
    border: 0;
    outline: none;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    background-color: $complimentary-color;
    &:hover {

    }

    &::after {
      content: attr(count);
      position: absolute;
      width: 30px;
      height: 30px;
      left: -1.5rem;
      top: 2.2rem;
      text-align: center;
      color: #fff;
      font-size: 14px;
      line-height: 28px;
      border-radius: 50%;
      border: 3px solid #fff;
      font-weight: bold;
      background-color: $primary-color;
    }

    &:hover::after {
      transform: scale(1.075);
    }
  }

  .hamburger {
    position: relative;
    width: 32px;
    height: 4px;
    background: #fff;
    margin: auto;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 32px;
      height: 4px;
      background: #fff;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }
  }

  .visible-links {
    display: inline-table;
    li {
      display: table-cell;
    }
  }

  //.visible-links {
  //  display: flex;
  //  flex-direction: column;
  //  li {
  //    flex-grow: 1;
  //  }
  //}

  .hidden-links {
    position: absolute;
    right: 0px;
    top: 100%;

    li {
      display: block;
    }
  }

  .hidden {
    visibility: hidden;
  }
}

//.navbar-default .navbar-brand {
//  color: $primary-color;
//  img{
//    padding-left: 18px;
//    width: 275px;
//    height: auto;
//  }
//}
//.navbar-collapse{
//  border-color: $tertiary-color !important;
//}
//.navbar-default .navbar-collapse, .navbar-default .navbar-form{
//  padding-top: 25px;
//}
//.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
//  color: $secondary-color;
//}
//.navbar-default .navbar-text {
//  color: $primary-color;
//}
//.navbar-default .navbar-nav {
//  clear: both;
//}
//.navbar-default .navbar-nav > li > ul > li > a {
//  text-transform: lowercase;
//}
//.navbar-default .navbar-nav > li {
//  margin-left: 0 !important;
//  border-bottom: 1px solid rgba(155,155,155,0.1);
//}
//
//.dropdown-menu {
//  border-radius: 0;
//  left: inherit !important;
//  .nav-item {
//    margin-left: 0 !important;
//    margin-right: 0 !important;
//    padding: 5px 10px;
//    transition: all 0.2s;
//    width: 100%;
//    text-align: center;
//    a {
//      background-color: transparent !important;
//    }
//    &:hover, &:active, &:focus {
//      background-color: $primary-color;
//      a {
//        color: #fff !important;
//      }
//    }
//    &.active {
//      background-color: $primary-color;
//      a {
//        color: #fff !important;
//      }
//      &:hover, &:active, &:focus {
//        background-color: darken($primary-color, 5);
//      }
//    }
//  }
//}
//
//.navbar-default .navbar-nav > li > a {
//  text-transform: lowercase;
//  padding: 10px 18px;
//  font-family: $primary-font;
//  color: #444;
//  font-weight: $font-weight-bold;
//  font-size: 16px;
//  border-bottom: 2px solid rgba(155,155,155,0.1);
//  @include animation(0.5s);
//}
//.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
//  color: $primary-color;
//  background: none;
//  border-bottom: 2px solid $primary-color;
//}
//.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
//  color: $primary-color;
//  border-bottom: 2px solid $primary-color;
//  background: none;
//}
//.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
//  color: $primary-color;
//  background: none;
//}
//.navbar-default .navbar-toggler:hover, .navbar-default .navbar-toggler:focus {
//  background-color: #fff;
//}
//.navbar-default .navbar-toggler{
//  color: #323232;
//  border: 1px solid #323232;
//  margin-top: 15px;
//  margin-bottom: 15px;
//}
//.navbar-default .navbar-toggler .icon-bar {
//  background-color: $primary-color;
//}
//.navbar-default .navbar-collapse,
//.navbar-default .navbar-form {
//  border-color: $primary-color;
//}
//.navbar-default .navbar-link {
//  color: $primary-color;
//}
//.navbar-default .navbar-link:hover {
//  color: $primary-color;
//}
//
//@media (min-width: 767px) and (max-width: 991px) {
//  .navbar-default .navbar-brand {
//    img{
//      width: 100%;
//      max-width: 180px;
//      height: auto;
//    }
//  }
//  .dropdown-menu {
//    position: relative;
//    .nav-item {
//
//    }
//  }
//}
//
//@media (max-width: 991px) {
//  .nav.navbar-nav{
//    background-color: #fff;
//    border: 1px solid;
//    margin-top: 25px;
//  }
//  .navbar-default .navbar-brand {
//    img{
//      width: 100%;
//      max-width: 200px;
//      height: auto;
//    }
//  }
//  .dropdown-menu {
//    width: 100%;
//    .nav-item {
//      text-align: left;
//    }
//  }
//  .navbar-header{
//    height: 100px;
//  }
//  .navbar-default .navbar-nav{
//    float: left !important;
//    margin-left: 5px;
//    width: 100%;
//  }
//  .navbar-default .navbar-collapse, .navbar-default .navbar-form{
//    padding-top: 0;
//    background-color: #282828;
//  }
//  .navbar-default .navbar-nav > li > a {
//    border-bottom: none;
//  }
//  .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
//    background-color: #333;
//    border-bottom: none;
//    @include border(2px);
//  }
//  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
//    background-color: $primary-color;
//    border-bottom: none;
//    color: #333;
//    @include border(2px);
//  }
//  .navbar-default .navbar-nav > li > a {
//    padding: 15px;
//  }
//  .navbar-default .navbar-nav .open .dropdown-menu > li.nav-item > a {
//    color: $primary-color;
//  }
//  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
//    color: $secondary-color;
//  }
//  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
//    color: $secondary-color;
//    background-color: #ffffff;
//  }
//}
// END Menu