.copyright {
  width: 100%;
  margin-top: 30px;
  padding: 40px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-align: center;
  .coded {
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    width: 24px;
  }
  p {
    display: inline-block;
    vertical-align: middle;
  }
  .copyright--footer {

  }
}
ul.social-icons {
  list-style: none;
  margin: 0 auto 25px;
  padding: 0;
  li {
    display: inline-block;
    padding: 2px 10px;
    img {
      width: 32px;
      height: auto;
    }
  }
}
ul.footer-links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    &:after {
      content: "|";
      display: inline-block;
    }
    &:last-child {
      margin-right: 0;
      &:after {
        content: "";
      }
    }
  }
}
.footer-logo {
  max-width: 80px;
  height: auto;
}
.copyright a {
  color: inherit;
}
.copyright-logo a {
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
}
.copyright .social-icons {
  text-align: center;
}
.copyright .social-icons .social-icon {
  display: inline-block;
  float: none;
  margin: 0 5px 5px 0;
  background-color: rgba(0,0,0,0.05) !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 30px;
}

