// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

input[type="submit"], button[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
  @extend .btn-block;
}

label {
  text-transform: lowercase;
  font-family: "Raleway", sans-serif;
}

label.required {
  &:after {
    display: inline-block;
    content: "*";
    font-size: 2em;
    position: absolute;
    top: -.2em;
    color: $primary-color;
  }
}

.help-block {
  color: #c2c2c2;
  font-style: italic;
  font-size: 0.8em;
  &.errors {
    color: #ff2c2c;
  }
}

input[type="text"], input[type="password"], input[type="email"], textarea, select {
  background: #fff;
  padding: 18px 8px;
  outline: none;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #666;
  width: 100%;
  margin: 0;
  display: block;
  margin-bottom: 5px;
  color: #999;
  -moz-box-shadow: #ececec 0px 1px 4px inset, #f8f8f8 -5px -5px 0px 0px, #f8f8f8 5px 5px 0px 0px, #f8f8f8 5px 0px 0px 0px, #f8f8f8 0px 5px 0px 0px, #f8f8f8 5px -5px 0px 0px, #f8f8f8 -5px 5px 0px 0px;
  -webkit-box-shadow: #ececec 0px 1px 4px inset, #f8f8f8 -5px -5px 0px 0px, #f8f8f8 5px 5px 0px 0px, #f8f8f8 5px 0px 0px 0px, #f8f8f8 0px 5px 0px 0px, #f8f8f8 5px -5px 0px 0px, #f8f8f8 -5px 5px 0px 0px;
  box-shadow: #ececec 0px 1px 4px inset, #f8f8f8 -5px -5px 0px 0px, #f8f8f8 5px 5px 0px 0px, #f8f8f8 5px 0px 0px 0px, #f8f8f8 0px 5px 0px 0px, #f8f8f8 5px -5px 0px 0px, #f8f8f8 -5px 5px 0px 0px;
  border: 1px solid #e8e8e8
}
input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, textarea:focus {
  border: 1px solid #e0e0e0;
  color: #333;
}

/*=== PAYMENT FORM ELEMENTS ===*/
#payment-input {
  overflow: hidden;
  margin-bottom: 13px;
  width: 100%;
  float: left;
}
#payment-input input {
  border: 1px solid #e8e8e8;
  width: 90%;
  font-size: 13px;
  color: #747474;
  padding: 20px 2%;
  float: left;
  margin: 0 1% 2%;
  border-radius: 2px;
}
#payment-textarea textarea {
  border: 1px solid #e8e8e8;
  width: 95%;
  height: 92px;
  font-size: 13px;
  color: #747474;
  padding: 20px 11px 10px;
  border-radius: 2px;
  margin-bottom: 10px;
}
#payment-input input:last-child {
  margin-right: 0;
}
#payment-input input:-moz-placeholder, #payment-textarea textarea:-moz-placeholder {
  color: #999;
}
#payment-input input:-ms-input-placeholder, #payment-textarea textarea:-ms-input-placeholder {
  color: #999;
}
#payment-input input::-webkit-input-placeholder, #payment-textarea textarea::-webkit-input-placeholder {
  color: #999;
}
#payment-textarea {
  width: 50%;
  float: right;
}
#payment-input label, #payment-textarea label{
  display: block;
  text-align:left;
  font-weight:bold;
  padding-left: 2%;
}
#payment-submit input[type="submit"] {
  padding: 20px 24px 20px 24px;
  border: 0 none;
  color: #ffffff;
  display: block;
  width: 20%;
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  float: right;
  height:57px;
  line-height:57px;
}
