.accordion {

  .card {
    button.card-header {
      display: block;
      width: 100%;
      height: 100%;
      color: $brand-primary;
      border: 0;
      background-color: transparent;
    }
    .card-header {
      .card-title {
        &:after {
          content: url('/dist/images/arrow-circle-down.svg');
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          right: 12px;
          top: 12px;
          transition: transform .5s ease-in-out;
        }
      }
    }
    [aria-expanded="true"] {
      .card-title {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
    [aria-expanded="false"] {
      .card-title {
        &:after {
          transform: rotate(0deg);
        }
      }
    }

    .card-block {

    }

  }
}
