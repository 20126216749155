.pagination {
  border-radius: 0;
  width: 100%;
  margin: 25px auto -30px;
  padding: 40px 0;
  background-color: #efefef;
}

.post-header {
  min-height: 250px;
  height: auto;
  position: relative;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 3px solid;
  a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 25px;
    color: #333;
    background: rgba(255,255,255,0.9);
    transition: all 0.2s;
    &:hover, &:active, &:focus {
      color: $primary-color;
      background: rgba(0,0,0,0.9);
    }
    .post-title {
      position: absolute;
      bottom: 25px;
      left: 0;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }
}
