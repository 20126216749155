/*==== Buttons ====*/

.btn{
  font-family: $primary-font;
}
.btn.btn-primary{
  font-weight: $font-weight-bold;
  background-color: $complimentary-color;
  color: #fff;
  border: 1px solid darken($complimentary-color, 10);
  @include border(0);
  @include animation(0.2s);
}

.btn.btn-primary:hover{
  background-color: lighten($complimentary-color, 5);
  border: 1px solid darken($complimentary-color, 10);
  @include boxshadow();
}

/*==== Service box ====*/

.service-box .service-description{
  a{
    color: #ff6136 ;
  }
  a:hover{
    color: #db462a;
  }
}