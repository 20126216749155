.page, .entry {
  padding-top: 25px;
  h1.title {
    margin-bottom: 50px;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;
  }
}

.blog {
  margin-bottom: 50px;
}

.community {
  margin-bottom: 50px;
}

#portfolio-wrap {
  position: relative;
  padding: 0;
  width: 100.1%;
  margin: 0 auto;
  display: block;
}
#portfolio-wrap .portfolio{
  border-top: 5px solid;
  border-bottom: 5px solid;
  border-left: 2.5px solid;
  border-right: 2.5px solid;
}
.portfolio-item.current {
  box-shadow: 0 0px 0px 10px rgba(255, 255, 255, 0.37);
  z-index: 101;
}
.portfolio-item {
  padding: 0;
  position: relative;
  overflow: hidden;
}
.portfolio-desc{
  width: 100%;
}
.portfolio-item .portfolio-image {
  overflow: hidden;
  display: block;
  position: relative;
}
.portfolio-item .portfolio-image img {
  width: 100%;
  height: auto;
}
.portfolio .portfolio-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  z-index: 4;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.3s ease-in-out;
  background: $primary-color;
}
.portfolio > a, .portfolio > a:hover {
  color: #ffffff;
}
.portfolio-item .portfolio > a:hover > .portfolio-overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}
.portfolio > a img {
  transition: all 0.3s ease-in-out;
}
.portfolio > a:hover img {
  transition: all 0.3s ease-in-out;
}
.portfolio .thumb-info {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.3s ease-in-out;
}
.portfolio a:hover .portfolio-overlay .thumb-info {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}
.portfolio-overlay .thumb-info em.zoom-light {
  top: 20%;
  font-size: 50px;
}
.portfolio-overlay .thumb-info span.title {
  top: 38%;
  font-size: 18px;
}
.portfolio-overlay .thumb-info span.text {
  top: 50%;
  font-size: 13px;
  width: 80%;
  em.tags {
    color: #fff;
  }
  em.icon {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 25px;
    height: auto;
  }
}
.portfolio-overlay .thumb-info span.title {
  color: #ffffff;
  width: 100%;
  position: absolute;
  text-align: center;
}
.portfolio-overlay .thumb-info span.text {
  color: #ffffff;
  width: 100%;
  position: absolute;
  text-align: center;
  font-weight: bold;
}
.portfolio-overlay .thumb-info em.zoom-light {
  color: #ffffff;
  width: 50px;
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -25px;
}

.post {
  margin-bottom: 100px;
}
.post-media {
  margin-bottom: 30px;
}
.post-title {
  font-family: $primary-font;
  font-size: 36px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
}
.post-title a {
  color: #151515;
}
.post-title a:hover {
  color: $primary-color;
}
.post-tags {
  margin-top: 20px;
}
.post-tags i {
  display: inline-table;
  margin-right: 4px;
  color: #868991;
  font-size: 16px;
  vertical-align: middle;
}
.post-tags ul {
  display: inline-block;
  padding: 0;
}
.post-tags li {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
}
.post-tags li:after {
  content: ' . ';
}
.post-tags li:last-child:after {
  content: '';
}
.post-tags li a {
  color: #868991;
  text-decoration: none;
}
.post-tags li a:hover {
  color: $primary-color;
}
.post-meta {
  font-size: 11px;
  margin-bottom: 20px;
  padding: 5px 0;
  border-top: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  font-size: 11px;
}
.post-meta span {
  text-align: right;
  float: right;
}