.card {
  // height: 100%;
  transform: scale(1);
  transition: all 0.2s;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: scale(1.1);
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }
}

.card-link {
  text-decoration: none;
  .card-block {

    color: #444;
    .card-title {
      font-size: 2rem;
    }
    .card-title, .card-text, .card-date, .card-comments, .card-tags {
      display: block;
      margin: .75rem;
    }

  }

}