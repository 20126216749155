.carousel {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}
.owl-item{
  position: relative;
  float: left;
  img {
    text-align: center;
    margin: 0 auto;
  }
}
.owl-nav {
  display: inherit !important;
  .owl-prev, .owl-next {
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    top: 50%;
    margin-top: -40px;
    width: 80px;
    height: 80px;
    position: absolute;
    transition: all 0.2s;
    i {
      color: #fff;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
      font-size: 72px;
      padding-top: 50%;
      margin-top: -15px;
      transition: all 0.2s;
      transform: scaleX(1);
    }
  }
  .owl-prev {
    left: 0;
    i {
      margin-left: -5px;
    }
    &:hover {
      i {
        margin-left: -25px;
      }
    }
    em.nav-previous {
      background-color: #fff;
      display: inline-block;
      content: url('/dist/images/arrow-left.svg');
      width: 50px;
      height: auto;
      &:hover {

      }
    }
  }
  .owl-next {
    right: 0;
    i {
      margin-right: -5px;
    }
    &:hover {
      i {
        margin-right: -25px;
      }
    }
    em.nav-next {
      background-color: #fff;
      display: inline-block;
      content: url('/dist/images/arrow-right.svg');
      width: 50px;
      height: auto;
    }
  }

}
@media (max-width: 991px) {
  .owl-nav {
    .owl-prev, .owl-next {
      top: 100%;
      margin-top: -100px;
    }
  }
}

/*
 * 	Default theme - Owl Carousel CSS File
 */

//.owl-theme .owl-nav {
//  margin-top: 10px;
//  text-align: center;
//  -webkit-tap-highlight-color: transparent; }
//.owl-theme .owl-nav [class*='owl-'] {
//  color: #FFF;
//  font-size: 14px;
//  margin: 5px;
//  padding: 4px 7px;
//  background: #D6D6D6;
//  display: inline-block;
//  cursor: pointer;
//  -webkit-border-radius: 3px;
//  -moz-border-radius: 3px;
//  border-radius: 3px; }
//.owl-theme .owl-nav [class*='owl-']:hover {
//  background: #869791;
//  color: #FFF;
//  text-decoration: none; }
//.owl-theme .owl-nav .disabled {
//  opacity: 0.5;
//  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }
