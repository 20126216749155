// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

/*	Colors	*/
$brand-primary: #e74c3c; // Red/Pink
$primary-color: $brand-primary; // Brand primary
$complimentary-color: #00b494;
$secondary-color: #C8CACB; // Light Gray
$tertiary-color: #F5F5F5; // Extra-light Gray
$quatenary-color: #404041; // Dark Gray
$hover-color: lighten($primary-color, 10);
$active-color: #848484;

// Typography
$primary-font: 'Nunito', sans-serif;

$font-size-h1: 4rem;
$font-size-h2: 3rem;
$font-size-h3: 2rem;
$font-size-h4: 1.5rem;

/*	Fonts	*/
$primary-font: $primary-font;
$font-weight-normal: 400;
$font-weight-bold: 700;

/* Functions */
@mixin border($n) {
  -webkit-border-radius: $n;
  -moz-border-radius: $n;
  border-radius: $n;
}
@mixin animation($n) {
  -moz-transition: all $n cubic-bezier(0.7, 0.01, 0.3, 1);
  -o-transition: all $n cubic-bezier(0.7, 0.01, 0.3, 1);
  -webkit-transition: all $n cubic-bezier(0.7, 0.01, 0.3, 1);
  transition: all $n cubic-bezier(0.7, 0.01, 0.3, 1);
}
@mixin boxshadow() {
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
}

// mixin
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}
.edge--bottom {
  @include angle(after);
}

.edge--bottom--reverse {
  @include angle(after, true);
}

.edge--top {
  @include angle(before);
}

.edge--top--reverse {
  @include angle(before, true);
}

.edge--both {
  @include angle(both);
}

.edge--both--reverse {
  @include angle(both, true);
}
