// Global block settings
section.block {

}


// Hero block
.block-heroSection {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 5px solid $complimentary-color;
  .bmm-intro {
    position: relative;
    /* do not overlap the header */
    left: 0;
    z-index: 1;
    height: 500px;
  }

  #videobg {
    position: relative;
    z-index: 1;
    div {
      width: 100%;
      height: 100%;
    }
    .video-overlay {
      width: 100%;
      height: 500px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: rgba(231,76,60,0.5);
      div {
        padding: 180px 15px 0 15px;
        h1 {
          font-weight: 300;
          display: inline;
          color: #fff;
          text-transform: lowercase;
        }
      }
    }
    #video {
      position: absolute !important;
      top: 0;
      left: 0;
      z-index: 4;
      video {
        width: 100%;
        height: auto;
      }
    }
    background-size: cover;
  }

}


// Open content block
.block-openContent {
  img {
    @extend .img-fluid;
  }
}

// Grid block
.block-columnGrid {
  img {
    @extend .img-fluid;
  }
}

// Forward path block
.block-forwardPaths {
  position: relative;
  .fp {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: #f6f6f6;
    transition: all .5s ease-in-out;
    transform: scale(1);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0);
    padding: 50px;
    &:hover, &:focus {
      transform: scale(1.1);
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
      z-index: 5;
    }
    &:after {
      content: "";
      width: 80px;
      height: 80px;
      top: -40px;
      left: -40px;
      border: 2px solid #eaeaea;
      transform: rotate(-45deg);
      display: block;
      position: absolute;
      background-color: $primary-color;
    }
    .fp--image {
      height: 256px;
      width: 100%;
      z-index: 1;
      position: relative;
      display: block;
      overflow: hidden;
      transition: all 0.2s;
      transform: scale(1);
      img, object, svg {
        z-index: 2;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 80%;
        height: auto;
        max-height: 256px;
        padding: 15px;
      }
      svg {

      }
    }
    .fp--link {
      text-decoration: none;
      cursor: pointer;
      color: #444;
      &:hover, &:focus, &:active {
        color: $primary-color;
      }
    }
    .fp--heading {
      text-align: center;
      margin: 0 auto;
      padding: 25px 0 10px 0;
      font-size: 2em;
      display: block;
      widht: 100%;
    }
    .fp--body {
      display: block;
      widht: 100%;
    }
  }
}

// Parallax block
.block-parallaxSection {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top: 5px solid $complimentary-color;
  border-bottom: 5px solid $complimentary-color;
  .parallax {
    min-height: 500px;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    .blockquote {
      background-color: rgba(255,255,255,0.9);
      border-radius: 5px;
      box-shadow: 0 2px 2px 2px rgba(0,0,0,0.2);
      border-left: 8px solid $complimentary-color;
      padding: 50px 15px;
      h2 {
        color: #444;
        //font-style: italic;
        //&:before {
        //  content: open-quote;
        //}
        //&:after {
        //  content: close-quote;
        //}
      }
      .attribution {
        font-weight: 400;
        &:before {
          content: "\2014";
        }
      }
      a {
        color: $complimentary-color;
        float: right;
        text-decoration: none;
        font-style: italic;
        transition: all 0.2s;
        border-bottom: 3px solid transparent;
        &:after {
          transition: all 0.2s;
          content: "\2192";
          display: inline-block;
        }
        &:hover, &:hover, &:focus {
          color: darken($complimentary-color, 10);
          border-bottom: 3px solid $complimentary-color;
          &:after {
            transform: translateX(5px);
          }
        }
      }
    }
  }
}

// Portfolio block
.block-portfolioSection {
  .bmm-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    -moz-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
    #ajaxpage {
      max-width: 100% !important;
    }
    .col-md-9, .col-md-3 {
      width: 100% !important;
    }
  }
  .bmm-panel::after {
    /* overlay layer */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    -webkit-transition: background 0.3s 0.3s;
    -moz-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
  }
  .bmm-panel.is-visible {
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    -moz-transition: visibility 0s 0s;
    transition: visibility 0s 0s;
  }
  .bmm-panel.is-visible::after {
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: background 0.3s 0s;
    -moz-transition: background 0.3s 0s;
    transition: background 0.3s 0s;
  }
  .bmm-panel.is-visible .bmm-panel-close::before {
    -webkit-animation: bmm-close-1 0.6s 0.3s;
    -moz-animation: bmm-close-1 0.6s 0.3s;
    animation: bmm-close-1 0.6s 0.3s;
  }
  .bmm-panel.is-visible .bmm-panel-close::after {
    -webkit-animation: bmm-close-2 0.6s 0.3s;
    -moz-animation: bmm-close-2 0.6s 0.3s;
    animation: bmm-close-2 0.6s 0.3s;
  }

  @-webkit-keyframes bmm-close-1 {
    0%, 50% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
    }
  }
  @-moz-keyframes bmm-close-1 {
    0%, 50% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(45deg);
    }
  }
  @keyframes bmm-close-1 {
    0%, 50% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  @-webkit-keyframes bmm-close-2 {
    0%, 50% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-45deg);
    }
  }
  @-moz-keyframes bmm-close-2 {
    0%, 50% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(-45deg);
    }
  }
  @keyframes bmm-close-2 {
    0%, 50% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .bmm-panel-header {
    position: fixed;
    width: 90%;
    height: 50px;
    line-height: 50px;
    background: rgba(255, 255, 255, 0.96);
    z-index: 2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    -webkit-transition: top 0.3s 0s;
    -moz-transition: top 0.3s 0s;
    transition: top 0.3s 0s;
  }
  .bmm-panel-header h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: #89ba2c;
    padding-left: 5%;
    margin-top: 1em;
  }
  .from-right .bmm-panel-header, .from-left .bmm-panel-header {
    top: -50px;
  }
  .from-right .bmm-panel-header {
    right: 0;
  }
  .from-left .bmm-panel-header {
    left: 0;
  }
  .is-visible .bmm-panel-header {
    top: 0;
    -webkit-transition: top 0.3s 0.3s;
    -moz-transition: top 0.3s 0.3s;
    transition: top 0.3s 0.3s;
  }
  @media only screen and (min-width: 768px) {
    .bmm-panel-header {
      width: 70%;
    }
  }
  @media only screen and (min-width: 1170px) {
    .bmm-panel-header {
      width: 50%;
    }
  }

  .bmm-panel-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60px;
  }

  .bmm-panel-container {
    position: fixed;
    width: 90%;
    height: 100%;
    top: 0;
    background: #efefef;
    z-index: 1;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .from-right .bmm-panel-container {
    right: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .from-left .bmm-panel-container {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  .is-visible .bmm-panel-container {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
  }
  @media only screen and (min-width: 768px) {
    .bmm-panel-container {
      width: 70%;
    }
  }
  @media only screen and (min-width: 1170px) {
    .bmm-panel-container {
      width: 50%;
    }
  }

  .bmm-panel-content {
    position: absolute;
    top: 0;
    max-width: 900px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 70px 5%;
    overflow: auto;
    /* smooth scrolling on touch devices */
    -webkit-overflow-scrolling: touch;
    #ajaxLoaded {
      width: 100%;
      max-width: 800px;
    }
  }
  .bmm-panel-content p {
    font-size: 14px;
    font-size: 0.875rem;
    color: #424f5c;
    line-height: 1.4;
    margin: 2em 0;
  }
  .bmm-panel-content p:first-of-type {
    margin-top: 0;
  }
  @media only screen and (min-width: 768px) {
    .bmm-panel-content p {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.6;
    }
  }

}