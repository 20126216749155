/* BODY */
body {
  width:100%;
  height:100%;
  overflow-x: hidden;
  font-family: 'Nunito', sans-serif;
}

// Adjust if changing $navbar-height variable in _variables.scss
body #content {
  font-size: 1.2rem;
  margin-top: 100px;
}

.animate, .draw{
  visibility: hidden;
}
.visible{
  visibility: visible !important;
}

h1, h2, h3, .text-styled {
  font-family: 'Lobster', cursive;
  text-transform: lowercase;
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  body {
    font-size: 1rem;
  }
}
@include media-breakpoint-down(sm) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3{
    font-size: 1rem;
  }
}
@include media-breakpoint-down(xs) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

article {
  img {
    @extend .img-fluid;
  }
}

.space-bottom20 {
  margin-bottom: 20px;
}
.space-top20 {
  margin-top: 20px;
}

/*
 * Add the animations for smoothState
 */

// Loader
.loader {
  display: none;
  width: 60px;
  height: 60px;
  margin: 20% auto;
}

.loader .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $complimentary-color;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.loader .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.loader .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.loader .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }
.loader .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.loader .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.loader .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.loader .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }
.loader .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.loader .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}

// Scene animations
.scene_element {
  -webkit-transition: -webkit-transform .28s, opacity .28s;
  -ms-transition: -o-transform .28s, opacity .28s;
  -o-transition: -o-transform .28s, opacity .28s;
  transition: transform .28s, opacity .28s;
}
.m-scene.slide-out .scene_element {
  -webkit-transform: translate3d( 0, 100px, 0 );
  -ms-transform: translate3d( 0, 100px, 0 );
  -o-transform: translate3d( 0, 100px, 0 );
  transform: translate3d( 0, 100px, 0 );
  opacity: 0;
}
.m-scene.slide-in .scene_element {
  -webkit-transform: translate3d( 0, 0, 0 );
  -ms-transform: translate3d( 0, 0, 0 );
  -o-transform: translate3d( 0, 0, 0 );
  transform: translate3d( 0, 0, 0 );
  opacity: 1;
}
