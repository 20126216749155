//.flyout {
//  position: fixed;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  z-index: 10000;
//  overflow-y: scroll;
//  .modal-content {
//    border-radius: 0;
//    .header {
//      text-align: center;
//      padding: 15px;
//      border-bottom: 3px solid $tertiary-color;
//    }
//  }
//}

/* .modal-fullscreen */

.modal-fullscreen {
  background: transparent;
  .header {
    text-align: center;
    padding: 15px;
    // margin: 25px 0 0 0;
  }
}
.modal-fullscreen .modal-content {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  .close {
    background: transparent !important;
  }
}
.modal-backdrop.modal-backdrop-fullscreen {
  background: #ffffff;
}
.modal-backdrop.modal-backdrop-fullscreen.in {
  opacity: .97;
  filter: alpha(opacity=97);
}

/* .modal-fullscreen size: we use Bootstrap media query breakpoints */

.modal-fullscreen .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .modal-fullscreen .modal-dialog {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .modal-fullscreen .modal-dialog {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .modal-fullscreen .modal-dialog {
    width: 1170px;
  }
}